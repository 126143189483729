var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("multi-select", {
    staticClass: "select-company-dropdown",
    attrs: {
      "track-by": "id",
      label: "name",
      "open-direction": "bottom",
      placeholder: "Select an existing business",
      options: _vm.companies,
    },
    on: { select: _vm.handleSelect },
    scopedSlots: _vm._u([
      {
        key: "option",
        fn: function ({ option }) {
          return [
            _vm.updateAvailable(option)
              ? _c(
                  "div",
                  { staticClass: "update-available" },
                  [
                    _c("feather-icon", {
                      staticClass: "alert-circle",
                      attrs: { type: "alert-circle" },
                    }),
                    _vm._v("\n      " + _vm._s(option.name) + "\n    "),
                  ],
                  1
                )
              : _c("div", [
                  _vm._v("\n      " + _vm._s(option.name) + "\n    "),
                ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }